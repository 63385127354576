const initialState = {
  loading: false,
  smartContract: null,
  isWhiteList: null,
  isDiscount: null,
  mintCost: null,
  TokenCost: null,
  errorMsg: "",
  totalSupply: null,
  maxSupply: null,
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        smartContract: action.payload.smartContract,
        isWhiteList: action.payload.isWhiteList,
        isDiscount: action.payload.isDiscount,
        mintCost: action.payload.mintCost,
        TokenCost: action.payload.TokenCost,
        errorMsg: action.payload.errorMsg,
        totalSupply: action.payload.totalSupply,
        maxSupply: action.payload.maxSupply,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
