import React from "react";
import { Link } from 'react-router-link';
import Logo from '../../images/logo/Logo.png'

const Header = () => {
return (
	<header className="header">
		<div className="container">
			{/* <div className="intro-lead-in">Hello Errbody</div> */}
			<div className="header-title">
				<h1>Welcome to the CroArmy's NFT Launchpad</h1>
				<h3>Your trusted source for vetted project launches.</h3>
				<h1 className="span loader">
					<span className='m'>C</span>
					<span className='m'>R</span>
					<span className='m'>O</span>
					<span className='m'>&nbsp;</span>
					<span className='m'>A</span>
					<span className='m'>R</span>
					<span className='m'>M</span>
					<span className='m'>Y</span>
				</h1>
			</div>
		</div>
	</header>
);
};

export default Header;
