import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchainArmyTokenNFT/blockchainActions";
import picture from "../images/drops/armytokennft.png";
import { FaPlus, FaMinus, FaBtc } from "react-icons/fa";


function Testing() {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const blockchain = useSelector((state) => state.blockchainTesting);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintError, setMintError] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintfeedback, setMintfeedback] = useState("");
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    TOKEN_ADDRESS: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    ISMINTING:true,
    DECIMALS:0,
    MAXMINT:0,
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  
  
  const claimNFTs = async () => {
    let cost = String(blockchain.TokenCost);
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei1 = cost * mintAmount*1.1;
    var totalCostWei2 = totalCostWei1.toLocaleString('fullwide', {useGrouping:false});
   var totalCostWei = 0;
   let totalGasLimit = String(gasLimit * mintAmount);

    const Web3 = require('web3');
    let web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");
  const tokenaddress = CONFIG.TOKEN_ADDRESS;
  const spenderaddress = CONFIG.CONTRACT_ADDRESS;
  const abiResponse = await fetch("/config/abiArmyToken.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const tokenabi = await abiResponse.json();
  const tokenContract = new web3.eth.Contract(tokenabi, tokenaddress);
  
  const checkallowance = await tokenContract.methods.allowance(wallet.account,spenderaddress).call();
  let checkallowance2 = checkallowance / 10 ** CONFIG.DECIMALS;
  let totalCostWei3 = totalCostWei2 / 10 ** CONFIG.DECIMALS;
  if (checkallowance2 >= totalCostWei3){

    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mintwithtoken(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: wallet.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);        
      })
      .then((receipt) => {
        setFeedback(`WOW, the ${CONFIG.NFT_NAME} is yours!`);
        setClaimingNft(false);
      });
    }
    else{
      setClaimingNft(true);
      setFeedback(`Approving`);
      const approvetx = await tokenContract.methods.approve(spenderaddress, totalCostWei2).send({from: wallet.account});
      
      const approveReceipt = await web3.eth.getTransactionReceipt(approvetx.transactionHash);
      setClaimingNft(false);
      setFeedback(`Approved! Click Buy to mint your NFT`);
      if (!approveReceipt) {
        console.error("Transaction receipt not found!");
        setClaimingNft(false);
        return;
      }
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const ismint = CONFIG.ISMINTING;

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > CONFIG.MAXMINT) {
      newMintAmount = CONFIG.MAXMINT;
    }
    setMintAmount(newMintAmount);
  };
  
  const getBlockchain = () => {
    if (wallet.account != null) {
      dispatch(connect(wallet));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/configArmyTokenNFT.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    
  }, []);

    
  useEffect(() => {
    getBlockchain();
  }, [wallet.account]);

  return (
    <>
      <section className="mint">
        <div className="container">
          <div className="row">
            <div className="title top">100k Army Token NFT</div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <img className="mint-picture" src={picture} alt="" />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="description top">
              We are excited to announce our new 100k $ARMY NFT, 🪖 which can be minted using 100k $ARMY. This NFT is fully backed by 100k $ARMY+, and owning it will earn you points towards our Top 30. Each NFT is worth 2 points, which will help our Whale Token holders climb the leaderboard. These 2 points will begin on the April 15th airdrop. So, look after April 1st to see the impact 🔥
You have the option to list/sell the NFTs peer-to-peer without affecting the market, or you can choose to stake them (future announcement), which is similar to staking a single $ARMY. If you prefer not to do either, you can burn the NFT to receive your 100k $ARMY back 14 days later.
Within this collection there are several multipliers 👀
1.0x, 1.1x, 1.2x, 1.3x, 1.4x, 1.5x and 5 legendary mints
              </div>
              <div>
                {wallet.account != null ? (
                  <>
                    <div className="connected-account">
                      Connected account:{" "}
                      <div className="account">{wallet.account}</div>
                    </div>
                    <div>
                      {blockchain.totalSupply == null ? (
                        <></>
                      ) : (
                        <>
                        <div className="totalsupply">
                          {blockchain.totalSupply} / {blockchain.maxSupply}
                        </div>

                        {(
                          <div>
                            Price {(blockchain.TokenCost/ 10 ** CONFIG.DECIMALS/1000).toLocaleString('fullwide', {useGrouping:false})}{"K $ARMY"}
                           
                          </div>
                        )}

                        <div className="mint-amount">
                          <button
                            className="set-amount"
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              decrementMintAmount();
                            }}
                          >
                            <FaMinus />
                          </button>
                          <div className="number">{mintAmount}</div>
                          <button
                            className="set-amount"
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              incrementMintAmount();
                            }}
                          >
                            <FaPlus />
                          </button>


                          <button
                            className="btn-primary"
                            disabled={claimingNft ? 1 : 0 || ismint ? 0 : 1}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs();
                            }}
                          >
                            {claimingNft ? "BUSY" : "BUY"}
                          </button>
                        </div>
                      </>
                    )}
                    </div>
                  </>
                ) : (
                  <div className="no-mint">No connection</div>
                )}
              </div>
              {Number(blockchain.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <div className="warning">The sale has ended.</div>
              ) : (
                <>
                  {wallet.account === "" ? (
                    <div>
                      {mintError ? (
                        <div className="text">
                          Something went wrong. Please connect to the{" "}
                          {CONFIG.NETWORK.NAME} network again to get your NFT
                        </div>
                      ) : (
                        <div className="text">
                          Please connect to the {CONFIG.NETWORK.NAME} network to
                          get your NFT
                        </div>
                      )}
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <div className="error">{blockchain.errorMsg}</div>
                        </>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      <div className="warning">{feedback}</div>
                      <div className="no-mint">{mintfeedback}</div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testing;
