import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import blockchainReducer from "./blockchain/blockchainReducer";
import blockchainCockheadzOGFounderReducer from "./blockchainCockheadzOGFounder/blockchainReducer";
import blockchainTestingReducer from "./blockchainTesting/blockchainReducer";
import blockchainArmyTokenNFTReducer from "./blockchainArmyTokenNFT/blockchainReducer";
import blockchainJackalsReloadedReducer from "./blockchainJackalsReloaded/blockchainReducer";
import blockchainCronosMarinesReducer from "./blockchainCronosMarines/blockchainReducer";
import blockchainLHSReducer from "./blockchainLHS/blockchainReducer";
import blockchainAlphaReducer from "./blockchainAlpha/blockchainReducer";
import blockchainBurnCroFounderReducer from "./blockchainBurnCroFounder/blockchainReducer"
//import blockchainSneakyDiggersReducer from "./blockchainSneakyDiggers/blockchainReducer";
import dataReducer from "./data/dataReducer";
import walletReducer from "./wallet/walletReducer";

const rootReducer = combineReducers({
  blockchain: blockchainReducer,
  blockchainCockheadzOGFounder: blockchainCockheadzOGFounderReducer,
  blockchainTesting: blockchainTestingReducer,
  blockchainArmyTokenNFT: blockchainArmyTokenNFTReducer,
  blockchainJackalsReloaded: blockchainJackalsReloadedReducer,
  blockchainCronosMarines: blockchainCronosMarinesReducer,
  blockchainLHS: blockchainLHSReducer,
  blockchainAlpha: blockchainAlphaReducer,
  blockchainBurnCroFounder: blockchainBurnCroFounderReducer,
  //blockchainSneakyDiggers: blockchainSneakyDiggersReducer,
  data: dataReducer,
  wallet: walletReducer,
});

const middleware = [thunk];
const composeEnhancers = compose(applyMiddleware(...middleware));

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers);
};

const store = configureStore();

export default store;
