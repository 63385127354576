import React from "react";
import { Link } from 'react-router-link';
import Logo from '../images/logo/Logo.png'

const Footer = () => {
return (
	<footer>
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-6 display-align-center text-align-left height-100">
					<span className="copyright">© 2022 CroArmy | All Right Reserved</span>
				</div>
				<div className="col-md-6 display-align-center text-align-right height-100">
					<img className="footer-logo" src={Logo} alt="Logo" />
				</div>
				{/* <div className="col-md-4">
					<ul className="list-inline quicklinks">
						<li><a href="#">Privacy Policy</a>
						</li>
						<li><a href="#">Terms of Use</a>
						</li>
					</ul>
				</div>  */}
			</div>
		</div>
	</footer>
);
};

export default Footer;
