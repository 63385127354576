import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchainAlpha/blockchainActions";
import picture from "../images/drops/933.png";
import { FaPlus, FaMinus } from "react-icons/fa";

function Alpha() {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const blockchain = useSelector((state) => state.blockchainAlpha);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintError, setMintError] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintfeedback, setMintfeedback] = useState("");
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    ISMINTING:true,
    MAXMINT:0,
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  
  const claimNFTs = async () => {
    let cost = blockchain.mintCost;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei1 = cost * mintAmount;
    var totalCostWei = totalCostWei1.toLocaleString('fullwide',{useGrouping:false});
    let totalGasLimit = String(gasLimit * mintAmount);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: wallet.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);        
      })
      .then((receipt) => {
        setFeedback(`WOW, the ${CONFIG.NFT_NAME} is yours!`);
        setClaimingNft(false);
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const ismint = CONFIG.ISMINTING;

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > CONFIG.MAXMINT) {
      newMintAmount = CONFIG.MAXMINT;
    }
    setMintAmount(newMintAmount);
  };
  
  const getBlockchain = () => {
    if (wallet.account != null) {
      dispatch(connect(wallet));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/configAlpha.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getBlockchain();
  }, [wallet.account]);

  return (
    <>
      <section className="mint">
        <div className="container">
          <div className="row">
            <div className="title top">AlphaClones </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <img className="mint-picture" src={picture} alt="" />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="description top">
              The Alpha Clone project comprises a series of 1000 distinctive NFTs. As blockchain technology evolves, the Cronos blockchain stands out for its speed, efficiency, and low gas fees, making it an ideal platform for investing and trading in digital artwork. For those who have a strong interest in the Cyber Clone project, this presents a unique chance to acquire NFTs in our upcoming release, named Alpha Clone! Additionally, we have a dedicated centralized discord server that caters to all Clone-related discussions. We guarantee that the Clone NFTs will be exceptional, scarce, and highly collectible. 
              </div>
              <div>
                {wallet.account != null ? (
                  <>
                    <div className="connected-account">
                      Connected account:{" "}
                      <div className="account">{wallet.account}</div>
                    </div>
                    <div>
                      {blockchain.totalSupply == null ? (
                        <></>
                      ) : (
                        <>
                        <div className="totalsupply">
                          {blockchain.totalSupply} / {blockchain.maxSupply}
                        </div>


                        {blockchain.isDiscount ? (
                          <div>
                            Member Price{" "}
                            {blockchain.mintCost / 1000000000000000000}{" "}
                            {CONFIG.NETWORK.SYMBOL}
                          </div>
                        ) : blockchain.isWhiteList ? (
                          <div>
                            White Listing Price{" "}
                            {blockchain.mintCost / 1000000000000000000}{" "}
                            {CONFIG.NETWORK.SYMBOL}
                          </div>
                        ) : (
                          <div>
                            Price {blockchain.mintCost / 1000000000000000000}{" "}
                            {CONFIG.NETWORK.SYMBOL}
                          </div>
                        )}
                        <div className="mint-amount">
                          <button
                            className="set-amount"
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              decrementMintAmount();
                            }}
                          >
                            <FaMinus />
                          </button>
                          <div className="number">{mintAmount}</div>
                          <button
                            className="set-amount"
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              incrementMintAmount();
                            }}
                          >
                            <FaPlus />
                          </button>

                          <button
                            className="btn-primary"
                            disabled={claimingNft ? 1 : 0 || ismint ? 0 : 1}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs();
                            }}
                          >
                            {claimingNft ? "BUSY" : "BUY"}
                          </button>
                        </div>
                      </>
                    )}
                    </div>
                  </>
                ) : (
                  <div className="no-mint">No connection</div>
                )}
              </div>
              {Number(blockchain.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <div className="warning">The sale has ended.</div>
              ) : (
                <>
                  {wallet.account === "" ? (
                    <div>
                      {mintError ? (
                        <div className="text">
                          Something went wrong. Please connect to the{" "}
                          {CONFIG.NETWORK.NAME} network again to get your NFT
                        </div>
                      ) : (
                        <div className="text">
                          Please connect to the {CONFIG.NETWORK.NAME} network to
                          get your NFT
                        </div>
                      )}
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <div className="error">{blockchain.errorMsg}</div>
                        </>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      <div className="warning">{feedback}</div>
                      <div className="no-mint">{mintfeedback}</div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Alpha;
