import React from "react";
import wizzle from "../images/collection/lion.png";
import coin from "../images/coins/coin.png";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AnimatedText from "react-animated-text-content";
// import Logo from '../../images/logo/Logo.png';
import {
  FaDiscord,
  FaTelegramPlane,
  FaRedditAlien,
  FaTwitter,
  FaMediumM,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const options = {
    loop: true,
    margin: 30,
    item: 4,
    dots: false,
    center: true,
    autoplay: true,
    slideTransition: "linear",
    autoplayTimeout: 3000,
    autoplaySpeed: 3000,
    autoplayHoverPause: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      768: {
        items: 2,
        nav: false,
      },
      992: {
        items: 4,
        nav: false,
      },
    },
  };
  const options2 = {
    loop: true,
    margin: 30,
    item: 4,
    dots: false,
    center: true,
    autoplay: true,
    slideTransition: "linear",
    autoplayTimeout: 3000,
    autoplaySpeed: 3000,
    autoplayHoverPause: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      768: {
        items: 2,
        nav: false,
      },
      992: {
        items: 4,
        nav: false,
      },
    },
  };
  return (
    <>
      <Header />
      <section>
        <div className="not-animated-paragraph">
          Our Token, with our NFT`s combined, make a community based ecosystem
          for growing wealth
        </div>
        <AnimatedText
          type="words" // animate words or chars
          animation={{
            x: "200px",
            y: "-20px",
            scale: 1.1,
            ease: "ease-in-out",
          }}
          animationType="float"
          interval={0.06}
          duration={2}
          tag="p"
          className="animated-paragraph"
          includeWhiteSpaces
          threshold={0.1}
          rootMargin="20%"
        >
          Our Token, with our NFT`s combined, make a community based ecosystem
          for growing wealth
        </AnimatedText>
      </section>
      <section>
        <div className="collection-title title">
          Current Drops{" "}
          <div className="fire">
            <div className="fire-left">
              <div className="main-fire"></div>
              <div className="particle-fire"></div>
            </div>
            <div className="fire-center">
              <div className="main-fire"></div>
              <div className="particle-fire"></div>
            </div>
            <div className="fire-right">
              <div className="main-fire"></div>
              <div className="particle-fire"></div>
            </div>
            <div className="fire-bottom">
              <div className="main-fire"></div>
            </div>
          </div>
        </div>
        <OwlCarousel className="owl-theme" {...options}>
          
          <div className="card seven">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">Baby Ryoshi Yoshi</div>
              <Link to="/Ryoshi"> 
                <div className="btn-primary">
                  <p>!!Mint now!!</p>
                </div>
              </Link>
            </div>
          </div>

          <div className="card five">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">BurnCroFounder</div>
              <Link to="/BurnCroFounder"> 
                <div className="btn-primary">
                  <p>!!Mint now!!</p>
                </div>
              </Link>
            </div>
          </div>



          <div className="card three">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">Cronos Marines</div>
              <Link to="/CronosMarines">
                <div className="btn-primary">
                  <p>Phase 2 Minting Now</p>
                </div>
              </Link>
            </div>
          </div>

          <div className="card one">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">Jackals Reloaded</div>
              <Link to="/JackalsReloaded">
                <div className="btn-primary">
                  <p>Minting Feb 7th 1900UTC</p>
                </div>
              </Link>
            </div>
          </div>


        </OwlCarousel>
        <div className="collection-title title margin-top-2">
          Sold Out{" "}
          <div className="fire">
            <div className="fire-left">
              <div className="main-fire"></div>
              <div className="particle-fire"></div>
            </div>
            <div className="fire-center">
              <div className="main-fire"></div>
              <div className="particle-fire"></div>
            </div>
            <div className="fire-right">
              <div className="main-fire"></div>
              <div className="particle-fire"></div>
            </div>
            <div className="fire-bottom">
              <div className="main-fire"></div>
            </div>
          </div>
        </div>
        <OwlCarousel className="owl-theme" {...options2}>

        <div className="card sold sixteen">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">Bulls and Bears</div>
              <div className="card-sold">(REFUNDED)</div>
            </div>
          </div>


          <div className="card sold fifteen">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">Football Squares 2023</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>


        <div className="card sold fourteen">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">100k Army Token NFT</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>


        <div className="card sold thirteen">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">Crotrons Panthers</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>

        <div className="card sold twelve">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">Bunny Gang Survivor</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>

        <div className="card sold eleven">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">CroArmy Jarheads</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>

          <div className="card sold ten">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">Colonel Tendies</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>
        
        <div className="card sold nine">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">Cockheadz by Design</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>

          <div className="card sold eight">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">Football Squares</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>

          <div className="card sold seven">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">CroCaveman</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>

          <div className="card sold five">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">8-Bit Army Bros</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>
          <div className="card sold three">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">BTC CroArmy Miner</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>

          <div className="card sold six">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">CroArmy Jackals</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>

          <div className="card sold four">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">CroArmy Coalition</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>
          <div className="card sold one">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">CroArmy Soldier</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>
          <div className="card sold two">
            <div className="card-background">
              <div className="card-circle"></div>
              <div className="card-title">CroArmy Burn</div>
              <div className="card-sold">(Sold Out)</div>
            </div>
          </div>
        </OwlCarousel>
      </section>
      <section className="token">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="join-description">
                <h1 className="title">Our $ARMY token is locked! 💪</h1>
                <h2 className="description">
                  Our token is being burned bi-weekly, is deflationary, and it
                  is whitelisted on Crodex with 0% swap fees. Experience our
                  tokens value increasing; with our capped supply decreasing.
                </h2>
                <div className="button-section">
                  <a
                    href="https://swap.crodex.app/#/swap?outputCurrency=0xb20115b8b176d72f077c4f93e95492870240a0b2"
                    target="_blank"
                  >
                    <div className="btn-primary max-width-300 buy-now">
                      <p>Buy Now</p>
                    </div>
                  </a>
                  <Link to="/token">
                    <div className="btn-primary max-width-300">
                      <p>Learn More</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 display-center">
              <div className="token-image">
                <img src={coin} alt="Coin" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 display-center">
              <div className="join-image">
                <img className="join-man" src={wizzle} alt="Wizzle" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="join-description">
                <h1 className="title">About CroArmy</h1>
                <h2 className="description">
                  We are a community looking to secure the crypto space, one
                  launch at a time. We have our own NFT collection that tied to
                  our treasury. All launch proceeds are redirected to our
                  treasury, and is then paid back to holders of our NFT, via
                  airdrops.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="requirements">
        <div className="collection-title title">Our Launchpad Requirements</div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="requirement-l">
                <div className="fire position">
                  <div className="fire-left">
                    <div className="main-fire"></div>
                    <div className="particle-fire"></div>
                  </div>
                  <div className="fire-center">
                    <div className="main-fire"></div>
                    <div className="particle-fire"></div>
                  </div>
                  <div className="fire-right">
                    <div className="main-fire"></div>
                    <div className="particle-fire"></div>
                  </div>
                  <div className="fire-bottom">
                    <div className="main-fire"></div>
                  </div>
                </div>
                <div className="fire-text">
                  The vetting process is a quick identity verification, social
                  media search, and an understanding of the projects
                  idea/forecast. If an applicant does not want to proceed with
                  this information, they will not appear on our launchpad.
                </div>
              </div>
              <div className="requirement-l">
                <div className="fire position">
                  <div className="fire-left">
                    <div className="main-fire"></div>
                    <div className="particle-fire"></div>
                  </div>
                  <div className="fire-center">
                    <div className="main-fire"></div>
                    <div className="particle-fire"></div>
                  </div>
                  <div className="fire-right">
                    <div className="main-fire"></div>
                    <div className="particle-fire"></div>
                  </div>
                  <div className="fire-bottom">
                    <div className="main-fire"></div>
                  </div>
                </div>
                <div className="fire-text">
                  We aim to slow down rugs that will come to CRONOS by this
                  interview process.{" "}
                </div>
              </div>
              <div className="requirement-l">
                <div className="fire position">
                  <div className="fire-left">
                    <div className="main-fire"></div>
                    <div className="particle-fire"></div>
                  </div>
                  <div className="fire-center">
                    <div className="main-fire"></div>
                    <div className="particle-fire"></div>
                  </div>
                  <div className="fire-right">
                    <div className="main-fire"></div>
                    <div className="particle-fire"></div>
                  </div>
                  <div className="fire-bottom">
                    <div className="main-fire"></div>
                  </div>
                </div>
                <div className="fire-text">
                  All of our launches will have at the minimum, this
                  verification done.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
