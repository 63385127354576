import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
// import Navbar from "./components/Navbar";
import { HashRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Token from "./pages/Token";
//import SneakyDiggers from './pages/SneakyDiggers';
import JackalsReloaded from './pages/JackalsReloaded';
import CronosMarines from './pages/CronosMarines';
import LHS from './pages/LHS';
import Ryoshi from './pages/Ryoshi';
import BurnCroFounder from './pages/BurnCroFounder';
import Testing from './pages/Testing';
import ArmyTokenNFT from './pages/ArmyTokenNFT';
import Alpha from './pages/Alpha';
import Footer from "./components/Footer";
import { walletConnect, walletDisconnect } from "./redux/wallet/walletActions";
import { fetchData } from "./redux/data/dataActions";
import { useDispatch, useSelector } from "react-redux";
import { FaDiscord, FaTelegramPlane, FaRedditAlien, FaTwitter, FaMediumM } from "react-icons/fa";
import { Link }
from 'react-router-dom';

function App() {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);

  const getData = () => {
    if (wallet.account !== "") {
      dispatch(fetchData(wallet.account));
    }
  };
  return (
    <HashRouter>
      <div className="nav">
        <input type="checkbox" id="nav-check" />
        <div className="nav-links socials">
          <a href="https://discord.com/invite/croarmy" target="_blank">
            <FaDiscord />
          </a>
          <a href="https://twitter.com/CroArmy_eth" target="_blank">
            <FaTwitter />
          </a>
          <a href="https://medium.com/@CroArmy" target="_blank">
            <FaMediumM />
          </a>
          <a href="https://t.me/cro_army" target="_blank">
            <FaTelegramPlane />
          </a>
          <a href="https://www.reddit.com/r/CroArmy_eth/" target="_blank">
            <FaRedditAlien />
          </a>
        </div>
        <div className="nav-btn">
          <label htmlFor="nav-check">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>
        <div className="nav-links">
          <Link to="/">HOME</Link>
          {/* <Link to="/drops">DROPS</Link>
          <Link to="/token">TOKEN</Link>
          <Link to="/about">ABOUT</Link> */}
        </div>
        <div className="nav-header">
          {/* <div className="nav-title">
				<a className="page-scroll" href="#"><img className="nav-logo" src={Logo} alt="Logo" /></a>
			</div> */}
          {wallet.account == null ? (
            <>
              <button
                className="btn-primary"
                onClick={(e) => {
                  dispatch(walletConnect());
                  getData();
                }}
              >
                Wallet Connect
              </button>
              {wallet.errorMsg !== "" ? (
                <div>{wallet.errorMsg}</div>
              ) : null}
            </>
          ) : (
            <>
              <button
                className="btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(walletDisconnect());
                }}
              >
                DISCONNECT
              </button>
            </>
          )}
        </div>
      </div>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/LHS" element={<LHS />} />
        <Route path='/Ryoshi' element={<Ryoshi />} />
        <Route path='/BurnCroFounder' element={<BurnCroFounder />} />
        <Route path='/JackalsReloaded' element={<JackalsReloaded />} />
        <Route path='/CronosMarines' element={<CronosMarines />} />
        <Route path='/Testing' element={<Testing />} />
        <Route path='/ArmyTokenNFT' element={<ArmyTokenNFT />} />
        <Route path='/Alpha' element={<Alpha />} />
        <Route path='/colonel' element={<Home />} />
        <Route path='/bunnygang' element={<Home />} />
        <Route path="/token" element={<Token />} />
      </Routes>
      <Footer />
    </HashRouter>
  );
}

export default App;
