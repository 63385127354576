const initialState = {
  loading: false,
  totalSupply: 0,
  maxSupply: 1,
  normalPrice: "",
  whitelistPrice: "",
  memberPrice: "",
  cost: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        maxSupply: action.payload.maxSupply,
        normalPrice: action.payload.normalPrice,
        whitelistPrice: action.payload.whitelistPrice,
        memberPrice: action.payload.memberPrice,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
