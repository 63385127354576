import React, { useEffect, useState, useRef } from "react";
import graph from '../images/collection/ARMYToken.jpg';
import coin from '../images/coins/coin.png';

function Token() {
  return (
    <>
      <section className="token">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="join-description">
                <h1 className="title top">
                  Tokenomics - Please swap $ARMY at Crodex Only
                </h1>
                <h2 className="description">
                  Deflationary token, with burn mechanisms in place. Token, combined with our NFT collection, will provide a dual income stream. Our full token ecosystem can be found below. It seems complex, but it’s an ecosystem to benefit everyone.
                </h2>
                <div className="button-section-center">
                  <a href="https://static1.squarespace.com/static/62321dbcbc71146c160c73bf/t/6242dfd91c6c623336828898/1648549851323/Tokenomics+-+%24ARMY+jpg.jpg" target="_blank">
                    <div className="btn-primary max-width-300 buy-now">
                      <p>PDF of Tokenomics</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 display-center">
              <div>
                <img src={graph} alt="Token Graph" className="mint-picture"/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 display-center">
              <div className="display-center">
                <img src={coin} alt="Coin" className="token-picture"/>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 display-center">
              <div className="join-description">
                <h1 className="title top">
                  Token Design
                </h1>
                <h2 className="description">
                  Designed with Greek Mythology in mind.
                </h2>
                <div className="button-section-center">
                  <a href="https://cronoscan.com/token/0xb20115b8b176d72f077c4f93e95492870240a0b2" target="_blank">
                    <div className="btn-primary max-width-300 buy-now">
                      <p>Token Contract</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Token;
